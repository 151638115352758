import React from 'react';
import { Text } from '@components/common';
import styles from './Information.module.scss';
import allowLocation from '../../assets/images/allowLocation.png';

export const Information = () => {
  return (
    <div className={styles.container}>
      <Text>
        Your web browser app (Safari, Chrome, etc.) may need your permission to
        share location information with the dispatcher. If you see a prompt like
        these below, please select <b>&quot;Allow&quot;</b>
      </Text>
      <img
        className={styles.image}
        src={allowLocation}
        alt='allow location example'
      />
      <Text className={styles.break}>
        You may need to reload the page or revisit the link sent by the
        dispatcher in order to continue. When your location is sent
        successfully, you will see a confirmation.
      </Text>
    </div>
  );
};

export default Information;
