import React from 'react';
import { TextProps } from './Typography.types';
import styles from './Typography.module.scss';

export const Text = ({ children, className, ...rest }: TextProps) => {
  return (
    <p className={`${styles.text} ${className}`} {...rest}>
      {children}
    </p>
  );
};