import { useState } from 'react';
import { useCodeFromUrl, useGeolocation } from '@hooks';
import { LoadingTypes } from '@commonTypes/common';
import { postCoordinates } from '@api/requests/coordinates';

const useLocationRequest = () => {
  const code = useCodeFromUrl();
  const { permission, latitude, longitude, error } = useGeolocation();
  const [sendingCoordinates, setSendingCoordinates] = useState<LoadingTypes>(
    LoadingTypes.idle,
  );
  const [sendingError, setSendingError] = useState<string>();

  const sendCoordinates = async () => {
    try {
      if (!code || !latitude || !longitude) return;
      setSendingCoordinates(LoadingTypes.pending);
      setSendingError(undefined);

      await postCoordinates({ code, latitude, longitude });

      setSendingCoordinates(LoadingTypes.fulfilled);
    } catch (error) {
      setSendingCoordinates(LoadingTypes.rejected);
      setSendingError('Failed to send coordinates');
    }
  };

  const handleSendCoordinates = () => {
    if (permission === 'granted' && latitude && longitude) {
      sendCoordinates();
    }
  };

  return {
    permission,
    latitude,
    longitude,
    error,
    sendingError,
    sendingCoordinates,
    handleSendCoordinates,
  };
};

export default useLocationRequest;
