import { useEffect, useState } from 'react';

const useGeolocation = () => {
  const [permission, setPermission] = useState<PermissionState>();
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();
  const [error, setError] = useState<string>();

  const requestGeolocationPermission = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPermission('granted');
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        setPermission('denied');
        switch (error.code) {
          case error.PERMISSION_DENIED:
            setError('Permission to send location denied.');
            break;
          case error.POSITION_UNAVAILABLE:
            setError('Location position unavailable.');
            break;
          case error.TIMEOUT:
            setError('User location request timed out.');
            break;
        }
      },
    );
  };

  useEffect(() => {
    if (navigator.geolocation) {
      requestGeolocationPermission();
    } else {
      setPermission('denied');
      setError('Geolocation is not supported by this browser.');
    }
  }, []);

  return {
    permission,
    latitude,
    longitude,
    error,
    requestGeolocationPermission,
  };
};

export default useGeolocation;
