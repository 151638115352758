import React from 'react';
import styles from './Loader.module.scss';
import { LoaderProps } from './Loader.types';

const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={styles.loader}>
      <div className={`${styles.spinner} ${className}`}></div>
    </div>
  );
};

export default Loader;
