import React from 'react';
import { Text } from '@components/common';
import { DisplayErrorMessageProps } from './DisplayErrorMessage.types';
import styles from './DisplayErrorMessage.module.scss';

const DisplayErrorMessage = ({ permission, error, sendingError }: DisplayErrorMessageProps) => {
  return (
    <>
      {
        sendingError ? (
          <Text className={styles.error}>
            {sendingError}
          </Text>
        )
        : null
      }
      {
        permission && error ? (
            <Text className={styles.error}>
              {error}
            </Text>
          )
        : null
      }
    </>
  );
};

export default DisplayErrorMessage;
