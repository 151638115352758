import React from 'react';
import styles from './DisplaySuccessMessage.module.scss';

const DisplaySuccessMessage = () => {
  return (
    <div className={styles.content}>
      <h1 className={styles.header}>Thank you!</h1>
      <p className={styles.info}>Logytext has received your location.</p>
      <p className={styles.info}>You can now close this.</p>
    </div>
  );
};

export default DisplaySuccessMessage;
