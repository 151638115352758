import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './routes';
import { LocationRequest } from '@pages';

const RoutesProvider = () => {
  return (
    <Routes>
      <Route element={<LocationRequest route='what' />} path={ROUTES.what} />

      <Route element={<LocationRequest route='location' />} path="/:code" />
      <Route element={<LocationRequest route='location' />} path='/' />
      <Route element={<LocationRequest route='location' />} path='*' />
    </Routes>
  );
};

export default RoutesProvider;