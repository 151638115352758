import apiClient from '@api';
import { SendCoordinatesPayload } from './coordinates.types';

export const postCoordinates = async ({ code, latitude, longitude }: SendCoordinatesPayload) => {
  await apiClient.post(
    '/location-request/receive-coordinates',
    {
      latitude,
      longitude,
    },
    {
      params: {
        shortCode: code,
      },
    },
  );
};