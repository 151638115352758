import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const useCodeFromUrl = () => {
  const { code } = useParams();
  const [codeFromUrl, setCodeFromUrl] = useState<string>();

  useEffect(() => {
    if (!code) {
      console.error('Error extracting code from URL: Invalid URL structure');
    } else {
      setCodeFromUrl(code);
    }
  }, []);

  return codeFromUrl;
};

export default useCodeFromUrl;
