import React from 'react';
import { ButtonProps } from './Button.types';
import styles from './Button.module.scss';
import { LoadingTypes } from '@commonTypes/common';
import { Loader } from '@components/common';

const Button = ({
  children,
  className,
  disabled,
  loading,
  ...rest
}: ButtonProps) => {
  const isLoading = loading === LoadingTypes.pending;

  return (
    <button
      disabled={disabled || isLoading}
      type='button'
      {...rest}
      className={`${styles.container} ${className}`}
    >
      {!isLoading && !!children && children}

      {isLoading && <Loader className={styles.loader} />}
    </button>
  );
};

export default Button;