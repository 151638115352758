import React from 'react';
import { Text } from '@components/common';
import styles from './Location.module.scss';
import { Link } from 'react-router-dom';

export const Location = () => {
  return (
    <div className={styles.container}>
      <Text>
        A Logytext dispatcher has requested your geographical location.
      </Text>
      <Text className={styles.break}>
        If you see are asked for permission to share location, please select <b>&quot;Allow&quot;</b>. It is safe to share this information with Logytext.
      </Text>
      <Link className={styles.link} to='/what'>What is this?</Link>
    </div>
  );
};

export default Location;
