import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { RoutesProvider } from '@providers';
import { Loader } from './components/common';
import './styles/index.css';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Suspense fallback={<Loader />}>
    <Router>
      <RoutesProvider />
    </Router>
  </Suspense>
);
